<template>
  <div class="index__container">
    <div class="index__inner">
      <div class="left">
        <h2>Index</h2>
        <ol>
          <li v-for="(chapter, index) in chapters" :class="{selected: index ==  selectedChapter}" :key="chapter.id" @click="setSelectedChapter(index)">{{ chapter.chapterTitle }}</li>
        </ol>
      </div>
      <div class="right">
        <div
          v-for="(slide, index) in chapters[selectedChapter].slides"
          :key="slide.id"
          class="index__thumbnail"
          @click="gotoSlide(index)"
        >
          <img :src="slide.imageUrl" alt="" />
          <span class="thumbnail__subtitle">{{ slide.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedChapter: 0
    }
  },
  computed: {
    chapters: function () {
      return this.$store.state.importedChapters;
    },
    browsingActive() {
      return this.$store.state.browsingActive;
    },
    endScreenActive() {
      return this.$store.state.endScreenActive;
    },
  },
  methods: {
    gotoSlide: function (e) {
      const payload = {'chapter': this.selectedChapter, 'slide': e};
      this.$store.dispatch("GOTOSLIDE", payload);
      this.$router.push({
        name: "Viewslide",
        params: { id: this.$store.state.presentationId },
      });
    },
    setSelectedChapter (e) {
      this.selectedChapter = e
    }
  },
  watch: {
    browsingActive(newBrowsing) {
      if (newBrowsing == false) {
        this.$router.push({
          name: "Viewslide",
          params: { id: this.$store.state.presentationId },
        });
      }
    },
    endScreenActive(newBrowsing) {
      if (newBrowsing == true) {
        this.$router.push({
          name: "Kontakt",
          params: { id: this.$store.state.presentationId },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.index__container {
  height: calc(100vh - var(--navbarheight) - var(--footerheight));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  ol li {
    cursor: pointer;
  }
}
.index__inner {
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  .left {
    flex: 1 0 auto;
    max-width: 50%;
    margin-right: 3rem;
  }
  .right {
    max-height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .index__thumbnail {
    cursor: pointer;
    flex: 0 1 240px;
    margin: 1rem;
    img {
      width: 100%;
    }
  }
}
</style>